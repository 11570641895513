import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "@reach/router";

import routes, {
  RouteNames, // eslint-disable-line no-unused-vars
} from "./routes";

interface LayoutLinkProps {
  activeRouteName: RouteNames | null;
  routeName: RouteNames;
  className?: string;
  children: React.ReactNode;
}
const LayoutLink = ({
  activeRouteName,
  routeName,
  className,
  children,
}: LayoutLinkProps) => {
  if (typeof window === "undefined") {
    return <ListItem button>{children}</ListItem>;
  }
  return (
    <ListItem
      button
      selected={routeName === activeRouteName}
      component={Link}
      to={routes[routeName].path}
      className={className}
    >
      {children}
    </ListItem>
  );
};
LayoutLink.defaultProps = {
  className: "",
};

export default LayoutLink;
