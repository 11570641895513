import { useState, useEffect } from "react";

import auth from "../firebase/auth";
import db from "../firebase/db";

const useCurrentUser = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean | null>(null);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    if (auth === null) {
      return undefined;
    }

    return auth.onAuthStateChanged((user) => {
      setIsUserLoggedIn(user !== null);
      setIsUserAdmin(null);
    });
  }, []);

  useEffect(() => {
    if (auth === null || db === null) {
      return undefined;
    }

    if (isUserLoggedIn && auth.currentUser !== null && isUserAdmin === null) {
      let cleanEffect: () => void = () => {};
      Promise.race([
        new Promise((resolve) => {
          cleanEffect = () => {
            resolve(null);
          };
        }),
        db
          .collection("userRoles")
          .doc(auth.currentUser.uid)
          .get()
          .then(() => {
            setIsUserAdmin(true);
          })
          .catch((error: firebase.FirebaseError) => {
            if (error.code === "permission-denied") {
              setIsUserAdmin(false);
              return;
            }
            throw error;
          }),
      ]);
      return cleanEffect;
    }
    return undefined;
  }, [isUserLoggedIn, isUserAdmin]);

  return {
    isUserAdmin: isUserLoggedIn === false ? false : isUserAdmin,
    currentUser:
      isUserAdmin === null || auth === null ? null : auth.currentUser,
    retry: () => {
      setIsUserAdmin(null);
    },
  };
};

export default useCurrentUser;
