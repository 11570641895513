import React, { useState, useEffect } from "react";
import * as t from "io-ts";
import { isRight } from "fp-ts/lib/Either";
import { PathReporter } from "io-ts/lib/PathReporter";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import db from "../../../../firebase/db";
import DATES, {
  EveningDateType, // eslint-disable-line no-unused-vars
} from "./DATES";

const Registration = t.strict({
  id: t.string,
  eveningDates: t.array(
    t.keyof({
      "09/07/2021": null,
      "10/07/2021": null,
      "11/07/2021": null,
      "15/07/2021": null,
      "16/07/2021": null,
      "17/07/2021": null,
      "18/07/2021": null,
      "20/07/2021": null,
      "21/07/2021": null,
      "01/08/2021": null,
    })
  ),
  type: t.keyof({ "dance-evening-planning": null }),
});

interface DateSelectorProps {
  selectedDate: EveningDateType | null;
  setSelectedDate: (newDate: EveningDateType | null) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({
  selectedDate,
  setSelectedDate,
}: DateSelectorProps) => {
  const [data, setData] = useState<{ id: EveningDateType; value: number }[]>(
    DATES.map((date) => ({ id: date, value: 0 }))
  );

  useEffect(() => {
    if (db === null) {
      return undefined;
    }

    let isSubscribed = true;
    const query = db
      .collection("registrations")
      .where("rejectedAt", "==", null)
      .where("type", "==", "dance-evening-planning");
    query.get().then((snapshot) => {
      if (!isSubscribed) {
        return;
      }
      const newData: {
        id: EveningDateType;
        value: number;
      }[] = DATES.map((date) => ({ id: date, value: 0 }));
      snapshot.docs
        .map((doc) => {
          const decodedRequest = Registration.decode({
            ...doc.data(),
            id: doc.id,
          });
          if (isRight(decodedRequest)) {
            return decodedRequest.right;
          }
          throw new Error(
            `Problem while decoding registration ${
              doc.id
            }\n${PathReporter.report(decodedRequest).join("\n")}`
          );
        })
        .forEach((registration: t.TypeOf<typeof Registration>) => {
          registration.eveningDates.forEach((date: EveningDateType) => {
            const index = newData.findIndex(({ id }) => id === date);
            newData[index].value += 1;
          });
        });
      setData(newData);
    });
    return () => {
      isSubscribed = false;
    };
  }, []);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSelectedDate(event.target.value as EveningDateType | null);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Date</FormLabel>
      <RadioGroup
        aria-label="Date"
        name="date"
        value={selectedDate}
        onChange={handleChange}
        row
      >
        {data.map(({ id, value }) => (
          <FormControlLabel
            key={id}
            value={id}
            control={<Radio />}
            label={`${id} (${value})`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default DateSelector;
