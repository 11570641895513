import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import * as t from "io-ts";
import { isRight } from "fp-ts/lib/Either";
import { PathReporter } from "io-ts/lib/PathReporter";

import db from "../../firebase/db";

const Dancer = t.strict({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  subscribeNewsletter: t.boolean,
});
const Request = t.strict({
  id: t.string,
  dancer1: Dancer,
  dancer2: t.union([t.null, Dancer]),
  dances: t.strict({
    soloDance: t.boolean,
    ballroom: t.boolean,
    mambo: t.boolean,
    discoBoogie: t.boolean,
    bachata: t.boolean,
    danceEvening: t.boolean,
  }),
  message: t.string,
  type: t.keyof({ "free-lesson": null }),
});

const PendingRequests: React.FC<{ path: string }> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [registrations, setRegistrations] = useState<
    t.TypeOf<typeof Request>[]
  >([]);
  useEffect(() => {
    if (db === null) {
      return undefined;
    }

    let isSubscribed = true;
    db.collection("registrations")
      .where("rejectedAt", "==", null)
      .where("type", "==", "free-lesson")
      .get()
      .then((snapshot) => {
        if (!isSubscribed) {
          return;
        }
        setRegistrations(
          snapshot.docs.map((doc) => {
            const decodedRequest = Request.decode({
              ...doc.data(),
              id: doc.id,
            });
            if (isRight(decodedRequest)) {
              return decodedRequest.right;
            }
            throw new Error(
              `Problem while decoding registration ${
                doc.id
              }\n${PathReporter.report(decodedRequest).join("\n")}`
            );
          })
        );
      })
      .finally(() => {
        if (!isSubscribed) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isSubscribed = false;
    };
  }, []);
  return (
    <TableContainer component={Paper}>
      {isLoading && <LinearProgress />}
      <Table>
        {!isLoading && registrations.length === 0 && (
          <caption>No pending registration yet!</caption>
        )}
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {registrations.map((registration) => (
            <TableRow key={registration.id}>
              <TableCell>{registration.dancer1.email}</TableCell>
              <TableCell>{registration.dancer1.firstName}</TableCell>
              <TableCell>{registration.dancer1.lastName}</TableCell>
              <TableCell>
                <IconButton aria-label="accept">
                  <CheckIcon />
                </IconButton>
                <IconButton aria-label="reject">
                  <ClearIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PendingRequests;
