import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MUIDataTable, {
  MUIDataTableColumn, // eslint-disable-line no-unused-vars
} from "mui-datatables";
import Paper from "@material-ui/core/Paper";
import * as t from "io-ts";
import { isRight } from "fp-ts/lib/Either";
import { PathReporter } from "io-ts/lib/PathReporter";
import { format } from "date-fns";

import db, { firestore } from "../../../firebase/db";

const Registration = t.strict({
  id: t.string,
  contact: t.strict({
    firstName: t.string,
    lastName: t.string,
    email: t.string,
  }),
  numberOfPeople: t.number,
  message: t.string,
  createdAt: t.any,
  type: t.keyof({ "drink-august-2023": null }),
});

interface EnhanceRegistration extends t.TypeOf<typeof Registration> {
  createdAt: Date;
}

const PendingRequests: React.FC<{ path: string }> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [registrations, setRegistrations] = useState<EnhanceRegistration[]>([]);
  useEffect(() => {
    if (db === null) {
      return undefined;
    }

    let isSubscribed = true;
    db.collection("registrations")
      .where("rejectedAt", "==", null)
      .where("type", "==", "drink-august-2023")
      .get()
      .then((snapshot) => {
        if (!isSubscribed) {
          return;
        }
        setRegistrations(
          snapshot.docs
            .map((doc) => {
              const decodedRequest = Registration.decode({
                ...doc.data(),
                id: doc.id,
              });
              if (isRight(decodedRequest)) {
                return decodedRequest.right;
              }
              throw new Error(
                `Problem while decoding registration ${
                  doc.id
                }\n${PathReporter.report(decodedRequest).join("\n")}`
              );
            })
            .map(
              (
                registration: t.TypeOf<typeof Registration>
              ): EnhanceRegistration => {
                return {
                  ...registration,
                  createdAt: registration.createdAt.toDate(),
                };
              }
            )
        );
      })
      .finally(() => {
        if (!isSubscribed) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isSubscribed = false;
    };
  }, []);
  const columns: MUIDataTableColumn[] = [
    {
      name: "contact.firstName",
      label: "First name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contact.lastName",
      label: "Last name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contact.email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Sent at",
      options: {
        customBodyRenderLite: (index: number) =>
          format(registrations[index].createdAt, "dd/MM/yyyy HH:mm"),
        filter: false,
        sort: true,
      },
    },
    {
      name: "numberOfPeople",
      label: "Number of people",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  return (
    <TableContainer component={Paper}>
      {isLoading && <LinearProgress />}
      <MUIDataTable
        title=""
        data={isLoading ? [] : registrations}
        options={{
          textLabels: {
            body: {
              noMatch: isLoading
                ? "Loading..."
                : "Sorry, no matching records found",
            },
          },
          enableNestedDataAccess: ".",
          expandableRows: true,
          onRowsDelete: ({ data }: { data: { dataIndex: number }[] }) => {
            data.forEach(({ dataIndex }) => {
              if (db === null) {
                return;
              }
              db.collection("registrations")
                .doc(registrations[dataIndex].id)
                .update({
                  rejectedAt: firestore.FieldValue.serverTimestamp(),
                });
            });
          },
          renderExpandableRow: (
            rowData: string[],
            { dataIndex }: { dataIndex: number }
          ) => {
            const registration = registrations[dataIndex];
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Message</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {registration.message}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
        columns={columns}
      />
    </TableContainer>
  );
};

export default PendingRequests;
