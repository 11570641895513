import { useLocation } from "@reach/router";

import routes, { routeNames } from "../components/Admin/routes";

const removeTrailingSlash = (pathname: string) => {
  if (pathname.charAt(pathname.length - 1) === "/") {
    return pathname.substr(0, pathname.length - 1);
  }

  return pathname;
};

const useActiveRouteName = (basePath: string | undefined) => {
  const location = useLocation();
  if (basePath === undefined) {
    return routeNames[0];
  }

  const pathname = removeTrailingSlash(location.pathname);

  const currentRoute = routeNames.find(
    (routeName) =>
      pathname === removeTrailingSlash(`${basePath}${routes[routeName].path}`)
  );

  return currentRoute === undefined ? null : currentRoute;
};

export default useActiveRouteName;
