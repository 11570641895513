import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "./Table";
import DateSelector from "./DateSelector";
import DATES, {
  EveningDateType, // eslint-disable-line no-unused-vars
} from "./DATES";

const useStyles = makeStyles((theme) => ({
  control: {
    padding: theme.spacing(2),
  },
}));

const PlanningDanceEvenings = () => {
  const [selectedDate, setSelectedDate] = useState<EveningDateType | null>(
    DATES[0]
  );
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.control}>
          <DateSelector
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Table selectedDate={selectedDate} />
      </Grid>
    </Grid>
  );
};

export default PlanningDanceEvenings;
