import React from "react";
import { Router } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import CssBaseline from "@material-ui/core/CssBaseline";

import Layout from "./Layout";
import routes, { routeNames } from "./routes";
import Dashboard from "./Dashboard";
import Invitations from "./Invitations";
import PendingRequests from "./PendingRequests";

export const useBasePath = () => {
  const { sitePage } = useStaticQuery(
    graphql`
      query {
        sitePage(internalComponentName: { eq: "ComponentAdmin" }) {
          path
        }
      }
    `
  );
  return sitePage.path;
};

const Admin = () => {
  const basePath = useBasePath();
  return (
    <>
      <CssBaseline />
      <Router>
        <Layout path={basePath}>
          {routeNames.map((routeName) => {
            const { Content, path } = routes[routeName];
            return <Content key={routeName} path={path === "" ? "/" : path} />;
          })}
          <Dashboard path="/" />
          <Invitations path="invitations" />
          <PendingRequests path="pending-requests" />
        </Layout>
      </Router>
    </>
  );
};

export default Admin;
