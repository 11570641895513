/*
 * Inspiration taken from
 * https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/dashboard
 */

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListSubheader from "@material-ui/core/ListSubheader";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FolderIcon from "@material-ui/icons/Folder";
import GroupIcon from "@material-ui/icons/Group";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import {
  RouteComponentProps, // eslint-disable-line no-unused-vars
} from "@reach/router";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import auth, { signInConfig } from "../../firebase/auth";
import routes from "./routes";
import useCurrentUser from "../../hooks/useCurrentUser";
import useActiveRouteName from "../../hooks/useActiveRouteName";
import LayoutLink from "./LayoutLink";
import UserNotAdmin from "./UserNotAdmin";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: "auto",
    marginLeft: -1 * theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      marginLeft: -1 * theme.spacing(9),
    },
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 0,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "absolute",
    top: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    paddingLeft: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(9),
    },
    transition: theme.transitions.create(["padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    paddingLeft: drawerWidth,
    transition: theme.transitions.create(["padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

type LayoutProps = RouteComponentProps & {
  children: React.ReactNode;
};

const Layout = ({ path, children }: LayoutProps) => {
  const activeRouteName = useActiveRouteName(path);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [isArchiveOpen, setIsArchiveOpen] = React.useState(
    activeRouteName !== null && routes[activeRouteName].isArchived
  );
  const { isUserAdmin, currentUser, retry } = useCurrentUser();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <main className={clsx(classes.content, open && classes.contentShift)}>
        <AppBar
          position="sticky"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {activeRouteName !== null ? routes[activeRouteName].title : ""}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" className={classes.container}>
          {isUserAdmin === null && <CircularProgress />}
          {isUserAdmin === false && currentUser !== null && (
            <UserNotAdmin user={currentUser} onRetry={retry} />
          )}
          {isUserAdmin !== null && currentUser === null && auth !== null && (
            <StyledFirebaseAuth uiConfig={signInConfig} firebaseAuth={auth} />
          )}
          {isUserAdmin && children}
        </Container>
      </main>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {typeof window !== "undefined" && (
          <>
            <List dense>
              <LayoutLink
                activeRouteName={activeRouteName}
                routeName="dashboard"
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </LayoutLink>
              <LayoutLink
                activeRouteName={activeRouteName}
                routeName="ClassRegistrationsSeptember2024"
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Class registrations" />
              </LayoutLink>
              <LayoutLink
                activeRouteName={activeRouteName}
                routeName="DanceEvening241221"
              >
                <ListItemIcon>
                  <LocalBarIcon />
                </ListItemIcon>
                <ListItemText primary="Dance evening" />
              </LayoutLink>
              <ListItem
                button
                onClick={() => setIsArchiveOpen((current) => !current)}
              >
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary="Archive" />
                {isArchiveOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={isArchiveOpen} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                  <ListSubheader className={classes.nested}>
                    2024 — H2
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEvening241129"
                  >
                    <ListItemText primary="Dance evening (24/11/24)" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>
                    2024 — H1
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="ClassRegistrationsJanuary2024"
                  >
                    <ListItemText primary="Class registrations" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEvening2024May10"
                  >
                    <ListItemText primary="Dance evening (10/05/10)" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEvening2024April19"
                  >
                    <ListItemText primary="Dance evening (19/04/24)" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEvening2024March14"
                  >
                    <ListItemText primary="Dance evening (14/03/24)" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEveningMarch202401"
                  >
                    <ListItemText primary="Dance evening (01/03/24)" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEveningJanary2024"
                  >
                    <ListItemText primary="Dance evening January" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>
                    2023 — November
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEveningRegistrationNovember2023"
                  >
                    <ListItemText primary="Dance evening" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>
                    2023 — September
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="ClassRegistrationsSeptember2023"
                  >
                    <ListItemText primary="Registration" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>
                    2023 — August
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DrinkRegistrationsAugust2023"
                  >
                    <ListItemText primary="Drink regitration" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>
                    2023 — January
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="DanceEveningsMarch2023"
                  >
                    <ListItemText primary="Dance evening (March)" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="ClassRegistrationsJanuary2023"
                  >
                    <ListItemText primary="Class registrations" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>
                    2022 — September
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="classRegistrationsSeptember2022"
                  >
                    <ListItemText primary="Class registrations" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>
                    2022 — January
                  </ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="openDoorJanuary2022"
                  >
                    <ListItemText primary="Open doors" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="classRegistrationsJanuary2022"
                  >
                    <ListItemText primary="Class registrations" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>2021</ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="planningDanceEvening"
                  >
                    <ListItemText primary="Dance evening planning" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="openDoor2021"
                  >
                    <ListItemText primary="Open doors" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="classRegistrations2021"
                  >
                    <ListItemText primary="Class registrations" />
                  </LayoutLink>
                  <ListSubheader className={classes.nested}>2020</ListSubheader>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="freeLessons"
                  >
                    <ListItemText primary="Free lessons" />
                  </LayoutLink>
                  <LayoutLink
                    className={classes.nested}
                    activeRouteName={activeRouteName}
                    routeName="classRegistrations2020"
                  >
                    <ListItemText primary="Class registrations" />
                  </LayoutLink>
                </List>
              </Collapse>
            </List>
            <Divider />
            <List dense>
              {currentUser !== null && (
                <ListItem
                  button
                  onClick={() => auth !== null && auth.signOut()}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log out" />
                </ListItem>
              )}
            </List>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default Layout;
