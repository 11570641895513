import Dashboard from "./Dashboard";
import FreeLessons from "./2020/FreeLessons";
import ClassRegistrationsJanuary2022 from "./January2022/ClassRegistrations";
import ClassRegistrations2021 from "./2021/ClassRegistrations";
import ClassRegistrations2020 from "./2020/ClassRegistrations";
import DanceEvenings2020 from "./2020/DanceEvenings";
import PlanningDanceEvenings from "./2021/PlanningDanceEvenings";
import OpenDoor2021 from "./2021/OpenDoor";
import OpenDoorJanuary2022 from "./January2022/OpenDoor";
import ClassRegistrationsSeptember2022 from "./September2022/ClassRegistrations";
import ClassRegistrationsJanuary2023 from "./January2023/ClassRegistrations";
import DanceEveningsMarch2023 from "./January2023/DanceEveningsMarch";
import DrinkRegistrationsAugust2023 from "./January2023/DrinkRegistrationsAugust";
import ClassRegistrationsSeptember2023 from "./2023September/ClassRegistrations";
import DanceEveningsNovember2023 from "./2023September/DanceEveningRegistrationNovember";
import ClassRegistrationsJanuary2024 from "./2024Januaty/ClassRegistrations";
import DanceEveningJanary2024 from "./2024Januaty/DanceEvening";
import DanceEveningMarch202401 from "./2024March/DanceEvening01";
import DanceEveningMarch202414 from "./2024March/DanceEvening14";
import DanceEvening2024April19 from "./2024April/DanceEvening19";
import DanceEvening2024May10 from "./2024May/DanceEvening10";
import ClassRegistrationsSeptember2024 from "./2024-H2/ClassRegistrations";
import DanceEvening241129 from "./2024-H2/DanceEvening_11_29";
import DanceEvening241221 from "./2024-H2/DanceEvening_12_21";

export type RouteNames =
  | "dashboard"
  | "freeLessons"
  | "classRegistrations2021"
  | "classRegistrations2020"
  | "danceEvenings2020"
  | "planningDanceEvening"
  | "openDoor2021"
  | "openDoorJanuary2022"
  | "classRegistrationsJanuary2022"
  | "classRegistrationsSeptember2022"
  | "ClassRegistrationsJanuary2023"
  | "ClassRegistrationsSeptember2023"
  | "DanceEveningsMarch2023"
  | "DrinkRegistrationsAugust2023"
  | "DanceEveningRegistrationNovember2023"
  | "ClassRegistrationsJanuary2024"
  | "DanceEveningJanary2024"
  | "DanceEveningMarch202401"
  | "DanceEvening2024March14"
  | "DanceEvening2024April19"
  | "DanceEvening2024May10"
  | "ClassRegistrationsSeptember2024"
  | "DanceEvening241129"
  | "DanceEvening241221";

const routes: Record<
  RouteNames,
  {
    path: string;
    Content: React.ReactType;
    title: string;
    isArchived: boolean;
  }
> = {
  dashboard: {
    path: "",
    Content: Dashboard,
    title: "Dashboard",
    isArchived: false,
  },
  freeLessons: {
    path: "archive/2020/free-lessons",
    Content: FreeLessons,
    title: "Free lessons",
    isArchived: true,
  },
  classRegistrations2021: {
    path: "archive/2021/class-registrations",
    Content: ClassRegistrations2021,
    title: "Class registrations",
    isArchived: true,
  },
  classRegistrations2020: {
    path: "archive/2020/class-registrations",
    Content: ClassRegistrations2020,
    title: "Class registrations",
    isArchived: true,
  },
  danceEvenings2020: {
    path: "archive/2020/dance-evenings",
    Content: DanceEvenings2020,
    title: "Dance evenings",
    isArchived: true,
  },
  planningDanceEvening: {
    path: "archive/2021/planning",
    Content: PlanningDanceEvenings,
    title: "Planning",
    isArchived: true,
  },
  openDoor2021: {
    path: "archive/2021/open-doors",
    Content: OpenDoor2021,
    title: "Open doors",
    isArchived: true,
  },
  openDoorJanuary2022: {
    path: "archive/january-2022/open-doors",
    Content: OpenDoorJanuary2022,
    title: "Open doors",
    isArchived: true,
  },
  classRegistrationsJanuary2022: {
    path: "archive/january-2022/class-registrations",
    Content: ClassRegistrationsJanuary2022,
    title: "Class registrations",
    isArchived: true,
  },
  classRegistrationsSeptember2022: {
    path: "archive/september-2022/class-registrations",
    Content: ClassRegistrationsSeptember2022,
    title: "Class registrations",
    isArchived: true,
  },
  ClassRegistrationsJanuary2023: {
    path: "archive/january-2023/class-registrations",
    Content: ClassRegistrationsJanuary2023,
    title: "Class registrations",
    isArchived: true,
  },
  ClassRegistrationsSeptember2023: {
    path: "archive/september-2023/class-registrations",
    Content: ClassRegistrationsSeptember2023,
    title: "Class registrations",
    isArchived: true,
  },
  DanceEveningsMarch2023: {
    path: "archive/january-2023/dance-evenings-march",
    Content: DanceEveningsMarch2023,
    title: "Dance evenings March",
    isArchived: true,
  },
  DrinkRegistrationsAugust2023: {
    path: "archive/august-2023/drink-registration",
    Content: DrinkRegistrationsAugust2023,
    title: "Drink registration",
    isArchived: true,
  },
  DanceEveningRegistrationNovember2023: {
    path: "archive/november-2023/dance-evening-registration",
    Content: DanceEveningsNovember2023,
    title: "Dance Evening",
    isArchived: true,
  },
  ClassRegistrationsJanuary2024: {
    path: "archive/2024-h1/class-registrations",
    Content: ClassRegistrationsJanuary2024,
    title: "Class registrations January 2024",
    isArchived: true,
  },
  DanceEveningJanary2024: {
    path: "archive/2024-h1/dance-evening-01-18",
    Content: DanceEveningJanary2024,
    title: "Dance evening (18/01/24)",
    isArchived: true,
  },
  DanceEveningMarch202401: {
    path: "archive/2024-h1/dance-evening-03-01",
    Content: DanceEveningMarch202401,
    title: "Dance evening (01/03/24)",
    isArchived: true,
  },
  DanceEvening2024March14: {
    path: "archive/2024-h1/dance-evening-03-14",
    Content: DanceEveningMarch202414,
    title: "Dance evening (14/03/24)",
    isArchived: true,
  },
  DanceEvening2024April19: {
    path: "archive/2024-h1/dance-evening-04-19",
    Content: DanceEvening2024April19,
    title: "Dance evening (19/04/24)",
    isArchived: true,
  },
  DanceEvening2024May10: {
    path: "archive/2024-h1/dance-evening-05-10",
    Content: DanceEvening2024May10,
    title: "Dance evening (10/05/24)",
    isArchived: true,
  },
  ClassRegistrationsSeptember2024: {
    path: "class-registrations",
    Content: ClassRegistrationsSeptember2024,
    title: "Class registrations",
    isArchived: false,
  },
  DanceEvening241129: {
    path: "archive/2024-h2/dance-evening-24-11",
    Content: DanceEvening241129,
    title: "Dance evening (29/11/24)",
    isArchived: true,
  },
  DanceEvening241221: {
    path: "dance-evening",
    Content: DanceEvening241221,
    title: "Dance evening (24/12/24)",
    isArchived: false,
  },
};

const routeNames: RouteNames[] =
  typeof window !== "undefined"
    ? [
        "dashboard",
        "freeLessons",
        "classRegistrations2021",
        "classRegistrations2020",
        "danceEvenings2020",
        "planningDanceEvening",
        "openDoor2021",
        "openDoorJanuary2022",
        "classRegistrationsJanuary2022",
        "classRegistrationsSeptember2022",
        "ClassRegistrationsJanuary2023",
        "ClassRegistrationsSeptember2023",
        "DanceEveningsMarch2023",
        "DrinkRegistrationsAugust2023",
        "DanceEveningRegistrationNovember2023",
        "ClassRegistrationsJanuary2024",
        "DanceEveningJanary2024",
        "DanceEveningMarch202401",
        "DanceEvening2024March14",
        "DanceEvening2024April19",
        "DanceEvening2024May10",
        "ClassRegistrationsSeptember2024",
        "DanceEvening241129",
        "DanceEvening241221",
      ]
    : [];

export { routes as default, routeNames };
