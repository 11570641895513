import * as firebase from "firebase/app";
import "firebase/auth";

import "./init";

const auth = typeof window !== "undefined" ? firebase.auth() : null;
const signInConfig = {
  signInFlow: "popup",
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
  signInOptions:
    auth !== null ? [firebase.auth.GoogleAuthProvider.PROVIDER_ID] : [],
};

export { auth as default, signInConfig };
