import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MUIDataTable, {
  MUIDataTableColumn, // eslint-disable-line no-unused-vars
} from "mui-datatables";
import Paper from "@material-ui/core/Paper";
import * as t from "io-ts";
import { isRight } from "fp-ts/lib/Either";
import { PathReporter } from "io-ts/lib/PathReporter";
import { format } from "date-fns";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import db, { firestore } from "../../../firebase/db";

type Unpacked<T> = T extends (infer U)[] ? U : T;

const NOTHING_SELECTED = "none";
const DATES = ["01/03/2024"] as const;

const Dancer = t.strict({
  firstName: t.string,
  lastName: t.string,
});
const Registration = t.strict({
  id: t.string,
  dancer1: Dancer,
  dancer2: t.union([t.null, Dancer]),
  message: t.string,
  createdAt: t.any,
  type: t.keyof({ "dance-evening-01-03-24": null }),
});

interface EnhanceRegistration extends t.TypeOf<typeof Registration> {
  createdAt: Date;
}

export type EveningDateType = typeof DATES[number];

const DanceEvenings: React.FC<{ path: string }> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<
    EveningDateType | typeof NOTHING_SELECTED
  >(DATES[0]);
  const [registrations, setRegistrations] = useState<EnhanceRegistration[]>([]);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDate(event.target.value as EveningDateType);
  };
  useEffect(() => {
    setRegistrations([]);
    if (db === null) {
      return undefined;
    }

    let isSubscribed = true;
    let query = db
      .collection("registrations")
      .where("type", "==", "dance-evening-01-03-24");
    if (selectedDate === NOTHING_SELECTED) {
      query = query.where("eveningDates", "==", []);
    } else {
      query = query.where("eveningDates", "array-contains", selectedDate);
    }
    query
      .get()
      .then((snapshot) => {
        if (!isSubscribed) {
          return;
        }
        setRegistrations(
          snapshot.docs
            .map((doc) => {
              const decodedRequest = Registration.decode({
                ...doc.data(),
                id: doc.id,
              });
              if (isRight(decodedRequest)) {
                return decodedRequest.right;
              }
              throw new Error(
                `Problem while decoding registration ${
                  doc.id
                }\n${PathReporter.report(decodedRequest).join("\n")}`
              );
            })
            .map(
              (
                registration: t.TypeOf<typeof Registration>
              ): EnhanceRegistration => {
                return {
                  ...registration,
                  createdAt: registration.createdAt.toDate(),
                };
              }
            )
        );
      })
      .finally(() => {
        if (!isSubscribed) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isSubscribed = false;
    };
  }, [selectedDate]);
  const columns: MUIDataTableColumn[] = [
    {
      name: "dancer1.firstName",
      label: "First name",
      options: {
        customBodyRenderLite: (index: number) => {
          const { dancer1, dancer2 } = registrations[index];
          return (
            <>
              <div>{dancer1.firstName}</div>
              {dancer2 !== null && (
                <>
                  <Divider />
                  <div>{dancer2.firstName}</div>
                </>
              )}
            </>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "dancer1.lastName",
      label: "Last name",
      options: {
        customBodyRenderLite: (index: number) => {
          const { dancer1, dancer2 } = registrations[index];
          return (
            <>
              <div>{dancer1.lastName}</div>
              {dancer2 !== null && (
                <>
                  <Divider />
                  <div>{dancer2.lastName}</div>
                </>
              )}
            </>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Sent at",
      options: {
        customBodyRenderLite: (index: number) =>
          format(registrations[index].createdAt, "dd/MM/yyyy HH:mm"),
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <TableContainer component={Paper}>
      {isLoading && <LinearProgress />}
      <MUIDataTable
        title={
          <Select
            onChange={handleChange}
            value={selectedDate}
            renderValue={() =>
              selectedDate === NOTHING_SELECTED ? <em>None</em> : selectedDate
            }
          >
            {DATES.map((date) => (
              <MenuItem key={date} value={date}>
                {date}
              </MenuItem>
            ))}
            <MenuItem value={NOTHING_SELECTED}>
              <em>None</em>
            </MenuItem>
          </Select>
        }
        data={isLoading ? [] : registrations}
        options={{
          textLabels: {
            body: {
              noMatch: isLoading
                ? "Loading..."
                : "Sorry, no matching records found",
            },
          },
          enableNestedDataAccess: ".",
          expandableRows: true,
          onRowsDelete: ({ data }: { data: { dataIndex: number }[] }) => {
            data.forEach(({ dataIndex }) => {
              if (db === null) {
                return;
              }
              db.collection("registrations")
                .doc(registrations[dataIndex].id)
                .update({
                  rejectedAt: firestore.FieldValue.serverTimestamp(),
                });
            });
          },
          renderExpandableRow: (
            rowData: string[],
            { dataIndex }: { dataIndex: number }
          ) => {
            const registration = registrations[dataIndex];
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Message</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {registration.message}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
        columns={columns}
      />
    </TableContainer>
  );
};

export default DanceEvenings;
