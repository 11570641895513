export type EveningDateType =
  | "09/07/2021"
  | "10/07/2021"
  | "11/07/2021"
  | "15/07/2021"
  | "16/07/2021"
  | "17/07/2021"
  | "18/07/2021"
  | "20/07/2021"
  | "21/07/2021"
  | "01/08/2021";

const DATES: EveningDateType[] = [
  "09/07/2021",
  "10/07/2021",
  "11/07/2021",
  "15/07/2021",
  "16/07/2021",
  "17/07/2021",
  "18/07/2021",
  "20/07/2021",
  "21/07/2021",
  "01/08/2021",
];

export default DATES;
