import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

type UserNotAdminProps = {
  onRetry: () => void;
  user: firebase.User;
};
const UserNotAdmin = ({ onRetry, user }: UserNotAdminProps) => (
  <Card>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Content not available!
      </Typography>

      <Typography variant="body2" color="textSecondary" component="p">
        You must be an admin to access this page. Please share your user id{" "}
        <Box
          fontFamily="Monospace"
          fontWeight="fontWeightBold"
          display="inline"
          component="span"
        >
          {user.uid}
        </Box>{" "}
        with your admistrator.
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" color="primary" onClick={onRetry}>
        Retry
      </Button>
    </CardActions>
  </Card>
);

export default UserNotAdmin;
